export default {
    install(Vue) {
        Vue.filter("kb", (val) => {
            return Math.floor(val / 1024);
        });
        Vue.filter("truncate", (text, length, suffix) => {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            }
            else {
                return text;
            }
        });
    }
};
