const MAX_IMAGE_SIZE = 1000000 * 5;
const MAX_ATTACHED_FILES_AMOUNT = 3;
const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
export class FormValidatorService {
    static validateEmail(email) {
        // eslint-disable-next-line
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }
    static validateAttachedFileExtension(files) {
        let correctFileTypes = true;
        for (const index in files) {
            const file = files[index];
            if (!allowedTypes.includes(file.type)) {
                correctFileTypes = false;
            }
        }
        return correctFileTypes;
    }
    static validateAttachedFileSize(files) {
        let correctFileSizes = true;
        for (const index in files) {
            const file = files[index];
            if (file.size > MAX_IMAGE_SIZE) {
                correctFileSizes = false;
            }
        }
        return correctFileSizes;
    }
    static validateAttachedFilesAmount(files) {
        let isAmountOk = true;
        if (files.length > MAX_ATTACHED_FILES_AMOUNT) {
            isAmountOk = false;
        }
        return isAmountOk;
    }
}
