var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import Watermark from "../components/Watermark.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "@/components/Loader.vue";
let Unsubscribe = class Unsubscribe extends Vue {
    constructor() {
        super();
        this.loading = true;
        this.unsubscribed = false;
        this.unsubscriptionID = "";
        this.axios = require("axios").default;
        this.apiUnsubscribeEndpointURL = process.env.VUE_APP_API_UNSUBSCRIBE_ENDPOINT_URL || "";
        this.key = process.env.VUE_APP_API_KEY || "";
    }
    async created() {
        if (this.$route.query.id) {
            this.unsubscriptionID = this.$route.query.id;
            try {
                await this.axios.post(this.apiUnsubscribeEndpointURL, {
                    id: this.unsubscriptionID
                }, {
                    headers: {
                        Authorization: this.key
                    }
                });
                this.unsubscribed = true;
            }
            catch (e) {
                // do something if necessary
            }
            finally {
                this.loading = false;
            }
        }
    }
    createUnsubscriptionPayload(email) {
        return {
            ContactListName: process.env.VUE_APP_NEWSLETTER_CONTACT_LIST_NAME || "",
            EmailAddress: email
        };
    }
    mounted() {
        console.log("mounted");
    }
    componentIsFree() {
        this.loading = false;
    }
};
Unsubscribe = __decorate([
    Component({
        components: {
            "watermark-component": Watermark,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader
        }
    })
], Unsubscribe);
export default Unsubscribe;
