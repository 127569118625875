var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
let Header = class Header extends Vue {
    constructor() {
        super(...arguments);
        this.navbarXsVisible = false;
        this.loginUrl = process.env.VUE_APP_LOGIN_URL;
        this.registerUrl = process.env.VUE_APP_REGISTER_URL;
        this.temporarilyHidden = true;
        this.languages = ['pl', 'en'];
        this.verboseLanguages = {
            pl: {
                flag: require("../../resources/images/flags/pl.svg"),
                verboseName: this.getLocaleVerboseName('languages.pl')
            },
            en: {
                flag: require("../../resources/images/flags/en.svg"),
                verboseName: this.getLocaleVerboseName('languages.en')
            }
        };
        this.submenu = {
            services: {
                visible: false
            },
            languages: {
                visible: false
            }
        };
    }
    created() {
        if (localStorage.getItem('locale')) {
            this.switchLocale(localStorage.getItem('locale'));
        }
    }
    getLocaleVerboseName(path) {
        if (this.$t) {
            return this.$t(path);
        }
        return "";
    }
    disableAllSubmenus() {
        for (const menu in this.submenu) {
            this.submenu[menu].visible = false;
        }
    }
    showNavbarXs() {
        this.navbarXsVisible = true;
    }
    hideNavbarXs() {
        this.navbarXsVisible = false;
    }
    switchLocale(locale) {
        if (this.$i18n.locale !== locale) {
            localStorage.setItem('locale', locale);
            this.$i18n.locale = locale;
            this.submenu.languages.visible = false;
        }
    }
};
__decorate([
    Prop()
], Header.prototype, "appTitle", void 0);
__decorate([
    Prop()
], Header.prototype, "isDesktop", void 0);
Header = __decorate([
    Component
], Header);
export default Header;
