var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from "vue-property-decorator";
import WatermarkCircle from "../components/WatermarkCircle.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
let PaymentGateways = class PaymentGateways extends Vue {
    constructor() {
        super();
        this.isDesktop = true;
        this.headerTitle = "Paymenterio";
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        this.localeAssets = {};
        this.temporarilyHidden = true;
    }
    created() {
        document.title =
            this.$t("general.site_titles.payment_gateways") +
                " - Paymenterio";
        this.loadLocaleAssets();
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
        }
    }
    loadLocaleAssets() {
        this.localeAssets = {
            "payment-methods": {
                name: "Payment Methods",
                path: require("../../resources/images/payment-methods-" +
                    this.$i18n.locale +
                    ".png")
            },
            "payment-gateways": {
                name: "Payment Gateways",
                path: require("../../resources/images/payment-gateways-" +
                    this.$i18n.locale +
                    ".svg")
            }
        };
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    onLocaleChange(value) {
        document.title =
            this.$t("general.site_titles.payment_gateways") +
                " - Paymenterio";
        this.loadLocaleAssets();
    }
};
__decorate([
    Watch("$i18n.locale")
], PaymentGateways.prototype, "onLocaleChange", null);
PaymentGateways = __decorate([
    Component({
        components: {
            "watermark-circle-component": WatermarkCircle,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader
        }
    })
], PaymentGateways);
export default PaymentGateways;
