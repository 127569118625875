var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from "vue-property-decorator";
import Watermark from "../components/Watermark.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
import SidebarMenu from "@/components/SidebarMenu.vue";
let BannedCountries = class BannedCountries extends Vue {
    constructor() {
        super(...arguments);
        this.isDesktop = true;
        this.headerTitle = this.$t("general.site_titles.banned_countries") +
            " - Paymenterio";
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        this.loading = true;
        // ---=== WATCHERS END ===---
    }
    created() {
        document.title =
            this.$t("general.site_titles.banned_countries") +
                " - Paymenterio";
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
        }
    }
    mounted() {
        this.loading = false;
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    onLocaleChange(value) {
        document.title =
            this.$t("general.site_titles.banned_countries") +
                " - Paymenterio";
    }
};
__decorate([
    Watch("$i18n.locale")
], BannedCountries.prototype, "onLocaleChange", null);
BannedCountries = __decorate([
    Component({
        components: {
            "watermark-component": Watermark,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader,
            "sidebar-menu-component": SidebarMenu
        }
    })
], BannedCountries);
export default BannedCountries;
