var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
import WatermarkBlend from "@/components/WatermarkBlend.vue";
let PrepaidCards = class PrepaidCards extends Vue {
    constructor() {
        super();
        this.isDesktop = true;
        this.headerTitle = "Paymenterio";
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        this.localeAssets = {};
    }
    created() {
        document.title =
            this.$t("general.site_titles.prepaid_cards") +
                " - Paymenterio";
        this.loadLocaleAssets();
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
        }
    }
    loadLocaleAssets() {
        this.localeAssets = {
            prepaid20: {
                name: "Prepaid Card 20",
                path: require("../../resources/images/prepaid20-" +
                    this.$i18n.locale +
                    ".svg")
            },
            prepaid50: {
                name: "Prepaid Card 50",
                path: require("../../resources/images/prepaid50-" +
                    this.$i18n.locale +
                    ".svg")
            },
            prepaid100: {
                name: "Prepaid Card 100",
                path: require("../../resources/images/prepaid100-" +
                    this.$i18n.locale +
                    ".svg")
            },
            "fast-payment": {
                name: "Fast Payment",
                path: require("../../resources/images/fast-payment-" +
                    this.$i18n.locale +
                    ".svg")
            }
        };
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    onLocaleChange(value) {
        document.title =
            this.$t("general.site_titles.prepaid_cards") +
                " - Paymenterio";
        this.loadLocaleAssets();
    }
};
__decorate([
    Watch("$i18n.locale")
], PrepaidCards.prototype, "onLocaleChange", null);
PrepaidCards = __decorate([
    Component({
        components: {
            "watermark-blend-component": WatermarkBlend,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader
        }
    })
], PrepaidCards);
export default PrepaidCards;
