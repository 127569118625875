var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Watch, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Watermark from "@/components/Watermark.vue";
import Loader from "@/components/Loader.vue";
import { FormValidatorService } from "@/services/formValidatorService";
import Typewriter from "typewriter-effect/dist/core";
let Home = class Home extends Vue {
    constructor() {
        super();
        this.isDesktop = true;
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        this.earnPointsPicture = require("../../resources/images/earn-points.jpg");
        this.billSectionBackground = require("../../resources/images/bill-background.svg");
        this.benefitSliderSlideChangeTimeout = 3000;
        this.benefitSliderCurrentSlideActiveIndex = 1;
        this.benefitSliderSlidesCount = 4;
        this.benefitSliderIntervalId = null;
        this.axios = require("axios").default;
        this.apiSubscribeEndpointURL = process.env.VUE_APP_API_SUBSCRIBE_ENDPOINT_URL || "";
        this.apiResponseMode = process.env.VUE_APP_API_RESPONSE_MODE || "";
        this.key = process.env.VUE_APP_API_KEY || "";
        this.newsletterSubscriptionTerms = this.$t("general.newsletter_subscription_conditions");
        this.welcomeSectionTitleText = this.$t("homepage.welcome_section.title");
        this.loading = false;
        this.imageSectionBodyText = this.$t("homepage.image_section.body");
        this.temporarilyHidden = true;
        // eslint-disable-next-line
        this.typeWriter = "";
        this.formData = {
            email: {
                value: ""
            },
            successfullySubmitted: false
        };
        this.formErrors = [];
        this.localeAssets = {};
        this.newsletterSubscriptionTerms = this.newsletterSubscriptionTerms.replace("{{url}}", this.$router.resolve({ name: "PrivacyPolicy" }).href);
    }
    // eslint-disable-next-line
    async submitNewsletterSubscription(event) {
        this.componentIsBusy();
        event.preventDefault();
        if (this.validateForm()) {
            // SEND API REQUEST
            try {
                const reCaptchaToken = await this.getReCaptchaToken();
                await this.axios.post(this.apiSubscribeEndpointURL, {
                    email: this.formData.email.value,
                    responseMode: this.apiResponseMode,
                    token: reCaptchaToken
                }, {
                    headers: {
                        Authorization: this.key
                    }
                });
                this.formData.successfullySubmitted = true;
            }
            catch (error) {
                if (error.response.data.message.includes("already exists")) {
                    this.formErrors.push(this.$t("general.errors.email_already_added").toString());
                }
                else if (error.response.data.message.includes("reCaptchaVerificationFailed")) {
                    this.formErrors.push(this.$t("general.errors.wrong_captcha").toString());
                }
                else {
                    this.formErrors.push(this.$t("general.errors.unhandled").toString() +
                        "<br /> <small>(" +
                        error.response.data.message +
                        ")</small>");
                }
            }
            finally {
                this.componentIsFree();
            }
        }
        else {
            this.componentIsFree();
        }
    }
    validateForm() {
        this.formErrors = [];
        if (!FormValidatorService.validateEmail(this.formData.email.value)) {
            this.formErrors.push(this.$t("general.errors.wrong_email").toString());
            return false;
        }
        return true;
    }
    disableAllBenefitSliderSlides() {
        clearInterval(this.benefitSliderIntervalId);
        this.benefitSliderCurrentSlideActiveIndex = 0;
        this.startBenefitSlider();
    }
    startBenefitSlider() {
        this.benefitSliderIntervalId = setInterval(() => {
            this.benefitSliderCurrentSlideActiveIndex += 1;
            if (this.benefitSliderCurrentSlideActiveIndex >
                this.benefitSliderSlidesCount) {
                this.benefitSliderCurrentSlideActiveIndex = 1;
            }
        }, this.benefitSliderSlideChangeTimeout);
    }
    created() {
        document.title =
            this.$t("general.site_titles.homepage") + " - Paymenterio";
        this.loadLocaleAssets();
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
        }
        this.startBenefitSlider();
        if (window.innerWidth < 767.98) {
            this.imageSectionBodyText = this.imageSectionBodyText.replace("<br />", "");
        }
    }
    mounted() {
        this.setTypeWriter();
    }
    getReCaptchaToken() {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
                    action: "NewsletterSubmit"
                })
                    .then(function (token) {
                    resolve(token);
                })
                    .catch(function (error) {
                    reject(error);
                });
            });
        });
    }
    componentIsBusy() {
        this.loading = true;
    }
    componentIsFree() {
        this.loading = false;
    }
    loadLocaleAssets() {
        this.localeAssets = {
            "entry-image": {
                name: "Entry image",
                path: require("../../resources/images/entry-image-" +
                    this.$i18n.locale +
                    ".png")
            },
            "entry-image-xs": {
                name: "Entry image",
                path: require("../../resources/images/entry-image-xs-" +
                    this.$i18n.locale +
                    ".png")
            },
            prepaid20: {
                name: "Prepaid Card 20",
                path: require("../../resources/images/prepaid20-" +
                    this.$i18n.locale +
                    ".svg")
            },
            prepaid50: {
                name: "Prepaid Card 50",
                path: require("../../resources/images/prepaid50-" +
                    this.$i18n.locale +
                    ".svg")
            },
            prepaid100: {
                name: "Prepaid Card 100",
                path: require("../../resources/images/prepaid100-" +
                    this.$i18n.locale +
                    ".svg")
            }
        };
    }
    setTypeWriter() {
        this.typeWriter = new Typewriter("#titleAddon", {
            strings: [
                this.$t("homepage.welcome_section.title_addon_1"),
                this.$t("homepage.welcome_section.title_addon_2")
            ],
            autoStart: true,
            loop: true
        });
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    async onLocaleChange(value) {
        document.title =
            this.$t("general.site_titles.homepage") + " - Paymenterio";
        this.loadLocaleAssets();
        this.welcomeSectionTitleText = this.$t("homepage.welcome_section.title");
        this.newsletterSubscriptionTerms = this.$t("general.newsletter_subscription_conditions");
        this.imageSectionBodyText = this.$t("homepage.image_section.body");
        await new Promise(f => setTimeout(f, 0));
        this.setTypeWriter();
    }
};
__decorate([
    Watch("$i18n.locale")
], Home.prototype, "onLocaleChange", null);
Home = __decorate([
    Component({
        components: {
            "watermark-component": Watermark,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader
        }
    })
], Home);
export default Home;
