import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { loadLocaleMessages } from "./utils";
import filters from "./plugins/filters";
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "/node_modules/bootstrap/scss/bootstrap.scss";
import "@/../resources/scss/global.scss";
Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || "en",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages() // set locale messages
});
Vue.config.productionTip = false;
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);
Vue.use(filters);
new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount("#app");
