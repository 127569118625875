var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from "vue-property-decorator";
import Watermark from "../components/Watermark.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
import SidebarMenu from "@/components/SidebarMenu.vue";
let DisbursementSchedule = class DisbursementSchedule extends Vue {
    constructor() {
        super(...arguments);
        this.isDesktop = true;
        this.headerTitle = this.$t("general.site_titles.withdrawal_schedule") +
            " - Paymenterio";
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        this.document = "";
        this.axios = require("axios").default;
        this.loading = true;
        // ---=== WATCHERS END ===---
    }
    created() {
        this.loadDocument(this.$i18n.locale);
        document.title =
            this.$t("general.site_titles.withdrawal_schedule") +
                " - Paymenterio";
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
        }
    }
    async loadDocument(lang) {
        const filename = "disbursement-schedule-" + lang + ".html";
        const response = await this.axios.get("/documents/" + filename);
        this.document = response.data;
        this.loading = false;
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    onLocaleChange(value) {
        this.document = "";
        this.loading = true;
        this.loadDocument(value);
        document.title =
            this.$t("general.site_titles.withdrawal_schedule") +
                " - Paymenterio";
    }
};
__decorate([
    Watch("$i18n.locale")
], DisbursementSchedule.prototype, "onLocaleChange", null);
DisbursementSchedule = __decorate([
    Component({
        components: {
            "watermark-component": Watermark,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader,
            "sidebar-menu-component": SidebarMenu
        }
    })
], DisbursementSchedule);
export default DisbursementSchedule;
