import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Unsubscribe from "@/views/Unsubscibe.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndConditions from "@/views/TermsAndConditions.vue";
import Contact from "@/views/Contact.vue";
import PaymentGateways from "@/views/PaymentGateways.vue";
import PrepaidCards from "@/views/PrepaidCards.vue";
import TermsAndConditionsPayments from "@/views/TermsAndConditionsPayments.vue";
import Pricing from "@/views/Pricing.vue";
import SupportedCountries from "@/views/SupportedCountries.vue";
import BannedIndustries from "@/views/BannedIndustries.vue";
import BannedCountries from "@/views/BannedCountries.vue";
import DisbursementSchedule from "@/views/DisbursementSchedule.vue";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "Najlepsze Płatności Internetowe - Paymenterio"
        }
    },
    {
        path: "/unsubscribe",
        name: "Unsubscribe",
        component: Unsubscribe,
        meta: {
            title: "Wypisz się z Newslettera - Paymenterio"
        }
    },
    {
        path: "/payment-gateways",
        name: "PaymentGateways",
        component: PaymentGateways,
        meta: {
            title: "Bramki Płatności - Paymenterio"
        }
    },
    {
        path: "/prepaid-cards",
        name: "PrepaidCards",
        component: PrepaidCards,
        meta: {
            title: "Karty Prepaid - Paymenterio"
        }
    },
    {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        meta: {
            title: "Lista Opłat - Paymenterio"
        }
    },
    {
        path: "/supported-countries",
        name: "SupportedCountries",
        component: SupportedCountries,
        meta: {
            title: "Lista Obsługiwanych Krajów - Paymenterio"
        }
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
            title: "Polityka Prywatności - Paymenterio"
        }
    },
    {
        path: "/terms-and-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
            title: "Warunki korzystania z usług - Paymenterio"
        }
    },
    {
        path: "/payments/terms",
        name: "TermsAndConditionsPayments",
        component: TermsAndConditionsPayments,
        meta: {
            title: "Regulamin Paymenterio Payments - Paymenterio"
        }
    },
    {
        path: "/payments/banned-industries",
        name: "BannedIndustries",
        component: BannedIndustries,
        meta: {
            title: "Lista Branż Nieobsługiwanych - Paymenterio"
        }
    },
    {
        path: "/payments/banned-countries",
        name: "BannedCountries",
        component: BannedCountries,
        meta: {
            title: "Lista Nieobsługiwanych Krajów - Paymenterio"
        }
    },
    {
        path: "/payments/disbursement-schedule",
        name: "DisbursementSchedule",
        component: DisbursementSchedule,
        meta: {
            title: "Harmonogram Wypłat"
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Skontaktuj się z Nami - Paymenterio"
        }
    }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active"
});
/*
router.afterEach(to => {
  // from
  Vue.nextTick(() => {
    document.title = to.meta.title || process.env.VUE_APP_TITLE;
  });
});
*/
export default router;
