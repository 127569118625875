var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import Watermark from "../components/Watermark.vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Loader from "../components/Loader.vue";
import { FormValidatorService } from "@/services/formValidatorService";
import * as axios from 'axios';
const contactAPIURL = process.env.VUE_APP_API_CONTACT_PATH ?? '';
/**
 */
let Contact = class Contact extends Vue {
    constructor() {
        super(...arguments);
        /**
         */
        this.loading = false;
        /**
         */
        this.isDesktop = true;
        /**
         */
        this.headerTitle = this.$t("general.site_titles.contact") + " - Paymenterio";
        /**
         */
        this.appTitle = process.env.VUE_APP_TITLE || "Paymenterio";
        /**
         */
        this.categories = [
            {
                name: 'contact_page.categories.payment_issue',
                slug: "paymentIssue",
                case: "transactionCase"
            },
            {
                name: 'contact_page.categories.fraud_issue',
                slug: "fraudIssue",
                case: "transactionCase"
            },
            {
                name: 'contact_page.categories.contact_request',
                slug: "contactRequest",
                case: "contactCase"
            },
            {
                name: 'contact_page.categories.offer_request',
                slug: "offerRequest",
                case: "offerCase"
            }
        ];
        /**
         */
        this.userFiles = [];
        this.processedFiles = [];
        this.asyncErrors = [];
        /**
         */
        this.formData = {
            category: {
                index: -1,
                focused: false,
                error: ""
            },
            transactionNumber: {
                value: "",
                transactionStatus: "",
                focused: false,
                error: ""
            },
            email: {
                value: "",
                focused: false,
                error: ""
            },
            www: {
                value: "",
                focused: false,
                error: ""
            },
            description: {
                value: "",
                focused: false,
                error: ""
            },
            companyDescription: {
                value: "",
                focused: false,
                error: ""
            },
            options: {
                showSecondStage: false
            },
            successfullySubmitted: false
        };
    }
    /**
     */
    attachFilesWithDropbox(e) {
        const droppedFiles = e.dataTransfer.files;
        if (!droppedFiles)
            return;
        [...droppedFiles].forEach((f) => {
            this.createImage(f);
            this.userFiles.push(f);
        });
    }
    /**
     */
    attachFilesWithClassicWindow(fileList) {
        const droppedFiles = fileList;
        if (!droppedFiles)
            return;
        [...droppedFiles].forEach((f) => {
            this.createImage(f);
            this.userFiles.push(f);
        });
    }
    /**
     * @param file
     */
    removeFile(file) {
        const index = this.userFiles.indexOf(file);
        this.userFiles = this.userFiles.filter((f) => {
            return f != file;
        });
        this.processedFiles.splice(index, 1);
    }
    /**
     */
    async createImage(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.processedFiles.push(e.target.result);
        };
        reader.readAsDataURL(file);
    }
    /**
     * @param e
     */
    async submitForm(e) {
        e.preventDefault();
        this.loading = true;
        if (this.validateForm()) {
            try {
                await this.processRequest();
            }
            catch (error) {
                this.asyncErrors.push('general.errors.unhandled');
            }
            finally {
                this.loading = false;
            }
        }
        else {
            this.loading = false;
        }
    }
    /**
     */
    async processRequest() {
        try {
            const reCaptchaToken = (await this.getReCaptchaToken("ContactRequest"));
            const _data = new FormData();
            _data.append('token', reCaptchaToken);
            _data.append('category', this.categories[this.formData.category.index].slug);
            _data.append('categoryName', this.$t(this.categories[this.formData.category.index].name));
            _data.append('transactionNumber', this.formData.transactionNumber.value);
            _data.append('email', this.formData.email.value);
            _data.append('wwwSite', this.formData.www.value);
            _data.append('description', this.formData.description.value);
            _data.append('companyDescription', this.formData.companyDescription.value);
            this.userFiles.forEach((file) => {
                _data.append('files[]', file);
            });
            const result = await axios.default.post(contactAPIURL, _data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            this.formData.successfullySubmitted = true;
        }
        catch (error) {
            const data = error?.response?.data ?? {};
            if (Object.keys(data).includes('message')) {
                this.asyncErrors.push('general.errors.' + data.message);
            }
            else {
                this.asyncErrors.push('general.errors.unhandled');
            }
            this.loading = false;
        }
    }
    /**
     */
    validateForm() {
        this.asyncErrors = [];
        if (!FormValidatorService.validateAttachedFileExtension(this.userFiles)) {
            this.asyncErrors.push('general.errors.wrong_file_type');
            return false;
        }
        if (!FormValidatorService.validateAttachedFileSize(this.userFiles)) {
            this.asyncErrors.push('general.errors.wrong_file_size');
            return false;
        }
        if (!FormValidatorService.validateAttachedFilesAmount(this.userFiles)) {
            this.asyncErrors.push('errors.wrong_file_amount');
            return false;
        }
        return true;
    }
    /**
     */
    getReCaptchaToken(actionName) {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
                    action: actionName
                }).then(function (token) {
                    resolve(token);
                }).catch(function (error) {
                    reject(error);
                });
            });
        });
    }
};
Contact = __decorate([
    Component({
        components: {
            "watermark-component": Watermark,
            "header-component": Header,
            "footer-component": Footer,
            "loader-component": Loader
        }
    })
], Contact);
export default Contact;
