var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
let Footer = class Footer extends Vue {
    constructor() {
        super(...arguments);
        this.temporarilyHidden = true;
    }
};
__decorate([
    Prop()
], Footer.prototype, "appTitle", void 0);
__decorate([
    Prop()
], Footer.prototype, "isDesktop", void 0);
__decorate([
    Prop()
], Footer.prototype, "theme", void 0);
__decorate([
    Prop()
], Footer.prototype, "lessTopSpacing", void 0);
Footer = __decorate([
    Component
], Footer);
export default Footer;
