var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable */
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let SidebarMenu = class SidebarMenu extends Vue {
    constructor() {
        super();
        this.isDesktop = true;
        this.submenuVisible = true;
        this.menuItems = [];
    }
    created() {
        if (window.innerWidth < 991.98) {
            this.isDesktop = false;
            this.submenuVisible = false;
        }
    }
    mounted() {
        this.getMenu();
    }
    getMenu() {
        if (this.menuType === 'PaymenterioGeneral') {
            this.menuItems = this.getPaymenterioGeneralMenu();
        }
        else if (this.menuType === 'PaymenterioPayments') {
            this.menuItems = this.getPaymenterioPaymentsMenu();
        }
    }
    getPaymenterioGeneralMenu() {
        return [
            {
                name: this.$t('general.site_titles.terms_and_conditions'),
                url: this.$router.resolve({ name: 'TermsAndConditions', params: {}, }).href
            },
            {
                name: this.$t('general.site_titles.supported_countries'),
                url: this.$router.resolve({ name: 'SupportedCountries', params: {}, }).href
            }
        ];
    }
    getPaymenterioPaymentsMenu() {
        return [
            {
                name: this.$t('general.site_titles.terms_and_conditions_payments'),
                url: this.$router.resolve({ name: 'TermsAndConditionsPayments', params: {}, }).href
            },
            {
                name: this.$t('general.site_titles.banned_countries'),
                url: this.$router.resolve({ name: 'BannedCountries', params: {}, }).href
            },
            {
                name: this.$t('general.site_titles.banned_industries'),
                url: this.$router.resolve({ name: 'BannedIndustries', params: {}, }).href
            },
            {
                name: this.$t('general.site_titles.withdrawal_schedule'),
                url: this.$router.resolve({ name: 'DisbursementSchedule', params: {}, }).href
            },
        ];
    }
    // ---=== WATCHERS START ===---
    // eslint-disable-next-line
    onLocaleChange(value) {
        this.getMenu();
    }
};
__decorate([
    Prop()
], SidebarMenu.prototype, "menuType", void 0);
__decorate([
    Watch("$i18n.locale")
], SidebarMenu.prototype, "onLocaleChange", null);
SidebarMenu = __decorate([
    Component
], SidebarMenu);
export default SidebarMenu;
